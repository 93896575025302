import client from "ApiClient";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card, CircularProgress, Grid, Icon, Pagination, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import logo from "assets/images/logo-170x24-white.png";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

/* eslint-disable */
export default function Search() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(searchParams.get("q") ?? "");
  const [zip, setZip] = useState(searchParams.get("zip") ?? "");
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const options = {
    method: "GET",
    url: "search",
    headers: {
      "content-type": "application/json",
    },
  };

  const searchJobs = async () => {
    if (search === "") return;
    setIsLoading(true);
    options.method = "GET";
    options.url = `search`;

    options.params = {
      q: search,
      zip: zip,
      page: page,
    };
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        setTotalPages(Math.round(response.totalresults / 20));
        setJobs(response.results);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    searchJobs();
  }, [page]);

  useEffect(() => {
    console.log(search);

    /*
    let lat = 0;
    let long = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (a) {
       
        if (a.coords) {
          for (var t in a.coords) {
            if (t === "latitude") lat = a.coords[t];
            if (t === "longitude") long = a.coords[t];
          }
        }

        var url =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          long +
          "&key=AIzaSyAgBCbbznAIT0Apq8LlvZd8_HbH32lsIIk";

        options.method = "GET";
        options.url = url;

        client
          .request(options)
          .then((response) => {
            setZip(response[0].address_components[6].long_name);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      alert("navigator.geolocation not supported.");
    }
      */
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Stack spacing={2} justifyContent="center" alignItems="center" marginBottom={5}>
      <MDBox
        bgColor="primary"
        variant="gradient"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        style={{ width: "100%" }}
      >
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <img src={logo} alt="search" />
          <MDInput
            style={{ backgroundColor: "white", marginLeft: "10px" }}
            placeholder="Company or position"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <MDInput
            style={{ backgroundColor: "white", marginLeft: "10px" }}
            placeholder="ZIP"
            value={zip}
            onChange={(event) => setZip(event.target.value)}
          />
          <MDButton
            variant="gradient"
            color="success"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setPage(1);
              searchJobs();
            }}
          >
            {" "}
            Find jobs
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox style={{ width: "80%" }} justifyContent="center" alignItems="center">
        {isLoading && (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            Loading...<CircularProgress></CircularProgress>
          </MDBox>
        )}
        {jobs && jobs.length > 0 && (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <Pagination
              disabled={isLoading}
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </MDBox>
        )}
        {jobs.map((job) => (
          <a href={job.url} onMouseDown={window[job.onmousedown]} target="_blank" rel="noreferrer">
            <Card key={job.id} sx={{ p: 2, m: 2 }}>
              <Grid container>
                <Grid item xs={12} md={2}>
                  <img src={job.logo} alt="company logo" />
                </Grid>
                <Grid item xs={12} md={10}>
                  <MDBox fontWeight="bold">{job.jobtitle}</MDBox>
                  <MDBox>{job.company}</MDBox>
                  <MDBox>
                    <MDTypography variant="body2" fontWeight="bold">
                      {job.city},{job.state},{job.country},{job.zip}
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="body2">{job.snippet}</MDTypography>
                  </MDBox>
                  <MDBox>
                    <Icon>schedule</Icon> {moment(job.date).fromNow()}
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </a>
        ))}
        {jobs && jobs.length > 0 && (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <Pagination
              disabled={isLoading}
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </MDBox>
        )}
      </MDBox>
    </Stack>
  );
}
